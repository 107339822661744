@import '../../variables';

.labeled-time-mask {
  display: flex;
  flex-direction: row;
  align-items: center;
  mask-image: linear-gradient(black, black);

  .labeled-time-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    p {
      padding: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: $system-dark;
      font-family: $font-family-normal;
      font-size: 14px;
      font-style: normal;
    }

    .content {
      font-weight: 400;

      p {
        white-space: nowrap;
      }
    }

    .divider {
      margin: 0 6px;
    }
  }

  .anim-x {
    transform: translateX(-100%);
    animation-name: slideX;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .anim-y {
    transform: translateY(-100%);
    animation-name: slideY;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes slideX {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideY {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }
}
