@import '../../../../../variables';

.score-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .score-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    background-image: linear-gradient(white, $xpektor-light-blue);
    box-shadow: 2px 2px 4px $xpektor-mid-blue;
    border: 1px solid $xpektor-light-gray;
    border-radius: 12px;
    padding: 24px;

    .score-gauge {
      position: relative;
      display: flex;
      height: 160px;
      aspect-ratio: 1 / 1;
      border: 10px solid;
      border-radius: 50%;

      .score-gauge-grade {
        position: absolute;
        font-family: $font-family-normal;
        font-size: 80px;
        font-weight: 800;
        line-height: 42px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        margin: 0;
      }
    }

    .score-gauge-disconnected {
      border-style: dashed;
    }

    .score-overall-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 36px;

      .beta-sticker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid black;
        border-radius: 24px;
        margin: 0;
        padding: 0;
      }

      p {
        font-size: 14px;
        font-family: $font-family-normal;
        color: black;
        padding: 0;
      }

      .overall-info-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .overall-info-disclaimer {
        margin-bottom: 16px;
        font-style: italic;
      }
    }

    .score-grade-summary {
      display: flex;
      flex-direction: column;
      max-width: 256px;
      flex: 1;
      gap: 6px;

      .grade-summary-title {
        color: black;
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 6px 0;
        padding: 0;
      }

      .summary-grade-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        font-size: 14px;

        width: 200px;
        text-align: center;
        padding: 0;
        border: none;
        border-radius: 6px;
        background: none;

        p {
          margin: 0;
          padding: 0;
        }

        .summary-grade {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          width: 30px;
          aspect-ratio: 10/8;
          border: 2px solid black;
          border-radius: 6px;
          font-weight: 600;
        }
        .summary-grade-title {
          font-size: 12px;
          color: black;
          font-weight: 600;
        }

        &:hover {
          transform: scale(1.025);
          background-image: linear-gradient(
            to right,
            $xpektor-mid-blue,
            rgba(255, 255, 255, 0)
          );
        }
      }
    }
  }

  .score-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    grid-gap: 12px;

    .score-content-column {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .grade-A {
    border-color: #28572a !important;
    color: #28572a !important;
  }
  .grade-B {
    border-color: #479346 !important;
    color: #479346 !important;
  }
  .grade-C {
    border-color: #f4d644 !important;
    color: #f4d644 !important;
  }
  .grade-D {
    border-color: #e5904a !important;
    color: #e5904a !important;
  }
  .grade-E {
    border-color: #e14844 !important;
    color: #e14844 !important;
  }
  .grade-- {
    border-color: rgb(109, 109, 109) !important;
    color: rgb(109, 109, 109) !important;
  }
}
