@import '../../variables';

.classic-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: rgba(0, 0, 0, 0.6);
    font-family: $font-family-normal;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    margin-bottom: 2px;
  }

  .form-input-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    label {
      white-space: nowrap;
    }

    .form-input {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      width: 100%;

      input {
        padding: 6px 12px;
        border: 1px solid $xpektor-light-gray;
        background-color: $xpektor-light-blue;
        outline: none;
        border-radius: 6px;
        font-size: 14px;
        font-family: $font-family-normal;
        width: 100%;

        &:focus {
          border-color: $xpektor-blue;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .form-input-icon {
        padding-right: 12px;
        position: absolute;
        right: 0;
        color: rgba(255, 255, 255, 0.5);
      }
      .clickable-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .form-text-area-container {
    position: relative;
    flex-direction: column;
    width: 100%;
    gap: 2px;

    label {
      font-size: 14px;
    }

    textarea {
      padding: 6px 12px;
      background-color: $xpektor-light-blue;
      border-radius: 6px;
      border: 1px solid $xpektor-light-gray;
      font-size: 14px;
      resize: none;
      outline: none;

      &:focus {
        border-color: $xpektor-blue;
      }
    }
  }

  .input-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: $xpektor-red;
    font-size: 11px;
    margin-bottom: 2px;
    padding-top: 2px;
    height: 18px;

    .input-info-icon {
      padding-right: 6px;
      padding-top: 1px;
    }

    p {
      margin: 0;
      padding: 0;
      font-family: $font-family-normal;
      color: $xpektor-red;
      font-size: 11px;
      line-height: 1.2;
    }
  }

  .input-info-ok {
    color: green;
    p {
      color: green;
    }
  }

  .form-dropdown-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;

    label {
      white-space: nowrap;
    }

    select {
      padding: 7.3px 12px;
      border: 1px solid $xpektor-light-gray;
      background-color: $xpektor-light-blue;
      outline: none;
      border-radius: 6px;
      font-size: 14px;
      font-family: $font-family-normal;

      &:focus {
        border-color: $xpektor-blue;
      }
    }
  }

  .form-file-upload-container {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 2px;
    overflow-x: hidden;

    input {
      font-size: 14px;
      line-height: 1.9;
    }
  }

  .form-date-input-container {
    display: flex;
    flex-direction: column;

    input {
      padding: 6px 12px;
      border: 1px solid $xpektor-light-gray;
      background-color: $xpektor-light-blue;
      outline: none;
      border-radius: 6px;
      font-size: 14px;
      font-family: $font-family-normal;
      width: 100%;

      &:focus {
        border-color: $xpektor-blue;
      }
    }
  }

  .xpektor-form-submit-button {
    display: flex;
    width: 100%;
    padding: 9px 0;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 6px;
    background-color: $xpektor-blue;
    margin-top: auto;
    color: white;
    font-family: $font-family-normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 24px */
    letter-spacing: 0.3px;
    transition: 0.125s;

    &:disabled {
      background-color: rgb(131, 168, 255);
    }

    &:hover:enabled {
      background-color: rgb(98, 153, 255);
      cursor: pointer;
    }
  }
}
