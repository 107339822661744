@import '../../../../variables';

.customer-clients-modal {
  .modal-dialog {
    width: 100%;
    max-width: 1000px;

    @media screen and (max-width: 900px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .modal-content {
    min-height: 90vh;

    .modal-body {
      .sysadmin-client-table {
        .table-item-row {
          .connection-text {
            font-weight: 600;
          }

          .connection-icon {
            font-size: 14px;
          }

          .error {
            color: $xpektor-red;
          }

          .broken {
            color: #e5904a;
          }

          .connected {
            color: darkgreen;
          }
        }

        .client-table-row {
          padding-left: 12px;
          padding-right: 12px;
          display: grid;
          grid-template-columns: 20% 15% 20% 1fr repeat(3, minmax(46px, 8%));
          box-shadow: 1px 2px 2px $xpektor-light-blue;
        }
      }
    }
  }
}
