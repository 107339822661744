@import '../../../../variables';

.customer-users-modal {
  .modal-dialog {
    width: 100%;
    max-width: 1000px;
  }

  .modal-content {
    min-height: 90vh;

    .modal-body {
      .new-user-item-row {
        display: grid;
        grid-template-columns: 50% 1fr;
        border: 1px solid $xpektor-blue;
        border-radius: 6px;
        padding: 12px 12px 0 12px;
        height: 80px;

        .xpektor-form {
          display: grid;
          grid-template-columns: 36% 40% 1fr;
          gap: 12px;
          margin: auto 0;

          form,
          select,
          label,
          input,
          select {
            font-size: 12px;
          }

          label {
            top: 10px;
          }

          input {
            padding: 16px 12px 2px 12px;
          }
          select {
            padding: 9.5px 12px 9.5px 12px;
          }

          .input-focus {
            label {
              top: 3px;
              padding: 0;
              font-size: 10px;
            }
          }
        }

        .action-row {
          display: flex;
          flex-direction: row;
          margin-top: 2px;
          justify-content: flex-end;
          gap: 12px;

          button {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 38px;
            border: 1px solid $xpektor-light-gray;
            border-radius: 6px;
            background-color: white;
            transition: 0.1s;

            p {
              font-size: 12px;
            }
            .icon {
              font-size: 14px;
            }
          }
          .confirm {
            &:hover {
              border-color: darkgreen;
              background-image: linear-gradient(white, rgb(232, 255, 232));
            }
            p,
            .icon {
              color: darkgreen;
            }
          }
          .cancel {
            &:hover {
              border-color: $xpektor-red;
              background-image: linear-gradient(white, $xpektor-light-red);
            }
            p,
            .icon {
              color: $xpektor-red;
            }
          }
        }
      }

      .sysadmin-user-table {
        .user-table-row {
          padding-left: 12px;
          padding-right: 12px;
          display: grid;
          grid-template-columns: 18% 20% 12% 1fr;
          box-shadow: 1px 2px 2px $xpektor-light-blue;

          .user-table-dynamic-content {
            display: grid;
            align-items: center;
            grid-template-columns: 16% 24% 30% 1fr;
            gap: 6px;
          }
        }
      }
    }
  }
}
