@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Syne:wght@400;500;600;700&display=swap');
html {
  overflow-x: hidden;
  width: 100%;
}

body {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: 'Rubik', sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}

input[type='file'] {
  width: 100%;
}
