@import '../../variables';

.arrow-tab-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  aspect-ratio: 4/1;

  &:hover {
    cursor: default;
  }

  div {
    display: flex;
  }

  .arrow-tab-outline {
    position: absolute;
    background-image: url('../../Images/banner-arrow-outline-bold.png');
    background-size: contain;
    height: 100%;
    aspect-ratio: inherit;
  }

  .arrow-tab-mask {
    position: relative;
    -webkit-mask-image: url('../../Images/banner-arrow.png');
    mask-image: url('../../Images/banner-arrow.png');
    mask-repeat: no-repeat;
    mask-size: contain;
    height: 100%;
    aspect-ratio: inherit;

    .arrow-tab-content {
      height: 100%;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-family: $font-family-normal;
        font-size: 12px;
        margin: 0;
        color: black;
      }
    }
  }
}
