@import '../../../../variables';

.sysadmin-panel-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  margin-bottom: 12px;
  width: 100%;
}
.sysadmin-panel-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
