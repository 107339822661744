@import '../../variables';

.xpektor-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    gap: 6px;
    padding: 6px;
    transition: background-color 0.125s;

    font-size: 14px;
    font-family: $font-family-normal;
    line-height: 1.2;

    .icon {
      font-size: 16px;
    }

    .loading-icon {
      font-size: 24px;
      animation: rotation 0.75s infinite linear;
    }
  }

  .tertiary-button {
    color: $xpektor-red;
    border: 1px solid $xpektor-red;
    background-color: white;

    .icon {
      color: $xpektor-red;
    }

    &:hover:enabled {
      background-color: $xpektor-light-red;
    }

    &:disabled {
      border-color: $xpektor-light-gray;
      color: rgba(0, 0, 0, 0.5);
    }

    .loading-icon {
      color: $xpektor-red;
    }
  }

  .secondary-button {
    color: $xpektor-blue;
    border: 1px solid $xpektor-blue;
    background-color: white;

    .icon {
      color: $xpektor-blue;
    }

    &:hover:enabled {
      background-color: $xpektor-light-blue;
    }

    &:disabled {
      border-color: $xpektor-light-gray;
      color: rgba(0, 0, 0, 0.5);
    }

    .loading-icon {
      color: $xpektor-blue;
    }
  }

  .primary-button {
    color: white;
    background-color: $xpektor-blue;
    border: none;

    .icon {
      color: white;
    }

    &:hover:enabled {
      background-color: rgb(98, 153, 255);
    }

    &:disabled {
      background-color: rgb(178, 198, 248);
    }

    .loading-icon {
      color: white;
    }
  }
}
