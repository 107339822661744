@import '../../../variables';

.screening-result-modal {
  .modal-content {
    height: 90vh;

    .modal-body {
      overflow-y: auto;
      .screening-result-wrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
        div {
          display: flex;
        }

        .search-hit-wrapper {
          flex-direction: column;
          background-image: linear-gradient(-45deg, white, $xpektor-light-blue);
          border: 1px solid $xpektor-light-gray;
          border-radius: 6px;
          padding: 0 6px;

          .hit-title-button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: none;
            background: none;
            padding: 12px;

            &:hover {
              .expand-icon {
                color: $xpektor-blue;
              }

              .hit-title {
                color: $xpektor-blue;
              }
            }

            .expand-icon {
              font-size: 14px;
            }
          }

          .source-list {
            flex-direction: column;

            .source-title {
              color: $xpektor-blue;
              b {
                font-size: 14px;
              }
            }

            .source-wrapper {
              flex-direction: column;
              padding: 6px 0;
              margin: 0 12px;
              border-bottom: 1px solid $xpektor-light-gray;

              &:first-child {
                border-top: 1px solid $xpektor-light-gray;
              }

              &:last-child {
                border-bottom: none;
                margin-bottom: 6px;
              }

              p {
                font-size: 12px;
              }

              a {
                word-wrap: break-word;
              }
            }

            .source-hidden {
              display: none;
            }
          }
        }
      }
    }
  }
}
