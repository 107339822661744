@import '../../../../variables';

.service-status-message-modal {
  .modal-header {
    border: none;
    .modal-title {
      font-size: 20px;
    }
  }
  .modal-body {
    padding: 24px;
    .xpektor-dropdown {
      width: 140px;
      margin-right: auto;
      margin-bottom: 24px;
      .severity-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          color: black;
        }
      }
    }

    .xpektor-form {
      .xpektor-form-submit-button {
        height: 40px;
      }
    }
  }

  .ok {
    color: green;
  }

  .warning {
    color: #e5904a;
  }

  .error {
    color: red;
  }
}
