@import '../../../../variables';

.system-admin-customers-wrapper {
  flex-direction: column;
  width: 100%;
  margin: 24px 0;
  gap: 12px;

  .nav-row {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .customer-table-container {
    flex-direction: column;
    align-items: center;
    max-width: 1500px;

    .customer-table-header {
      flex-direction: row;
    }

    .customer-list {
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .customer-entry {
        flex-direction: column;
        width: 100%;

        .customer-entry-card {
          flex-direction: row;
          width: 100%;
          border: 1px solid $xpektor-light-gray;
          border-radius: 6px;
          padding: 12px 24px;
          gap: 6px;
          box-shadow: 2px 2px 4px $xpektor-mid-blue;
          background-color: white;

          p {
            font-family: $font-family-normal;
            color: black;
            margin: 0;
            font-size: 14px;
          }

          .content-column {
            flex-direction: column;
            border-right: 1px solid $xpektor-light-gray;
            flex: 1;
            gap: 12px;
            padding-right: 12px;

            .data-content-row {
              flex-direction: row;
              flex-wrap: wrap;
              gap: 12px;

              .data-content-column {
                flex-direction: column;
                gap: 12px;

                &:last-child {
                  border: none;
                }

                .data-column {
                  flex-direction: column;
                  padding: auto 0;
                  flex-grow: 1;

                  p {
                    font-size: 12px;
                  }
                }
              }
            }

            .gap-row {
              flex-direction: row;
              align-items: center;
              gap: 12px;
              flex-wrap: wrap;
            }

            .title-row {
              flex-direction: row;
              align-items: center;
              gap: 12px;
              height: 28px;

              .title {
                font-size: 22px;
                font-weight: 600;
                line-height: 1;
              }

              .tag {
                flex-direction: row;
                align-items: center;
                border: 1px solid $xpektor-light-gray;
                border-radius: 6px;
                background-image: linear-gradient(white, $xpektor-light-blue);
                padding: 4px 24px;

                p {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 1.2;
                }
              }
              .advice {
                background-image: linear-gradient(white, pink);
              }

              .finance {
                background-image: linear-gradient(white, rgb(224, 242, 255));
              }

              .subsegment {
                background-image: linear-gradient(white, rgb(236, 236, 236));
              }
            }
          }

          .logo-column {
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: 15%;
            min-width: 100px;
            gap: 24px;
            padding: 0 0 0 calc(min(1%, 24px));

            .xpektor-button {
              max-width: 100%;
            }

            .logo {
              object-fit: contain;
              max-width: 100%;
              height: 80px;
              border: 1px solid $xpektor-light-gray;
              border-radius: 6px;
            }

            .missing-logo {
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 50px;
              width: 200px;
              p {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }

        .sub-customer-container {
          flex-direction: column;
          background-color: $xpektor-light-blue;
          border-left: 1px solid $xpektor-light-gray;
          border-right: 1px solid $xpektor-light-gray;
          border-bottom: 1px solid $xpektor-light-gray;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          padding: 12px;
          margin: 0 6px;

          .sub-customer-title-column {
            flex-direction: column;
            gap: 6px;
            padding: 6px 0 12px 0;
          }

          .sub-customer-list {
            max-height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
        .sub-customer-tab {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          margin-left: 12px;
          margin-right: auto;
          gap: 12px;
          min-width: 200px;
          border: 1px solid $xpektor-light-gray;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          box-shadow: 2px 2px 4px $xpektor-mid-blue;
          background-image: linear-gradient(white, $xpektor-light-blue);

          &:hover {
            background-image: linear-gradient(white, $xpektor-mid-blue);
          }

          p {
            font-size: 12px;
            margin: 0;
            font-weight: 600;
            font-family: $font-family-normal;
          }

          .expand-icon {
            font-size: 12px;
          }
        }
      }

      .no-hits-item {
        font-size: 14px;
        font-family: $font-family-normal;
        border: 1px solid $xpektor-light-gray;
        border-radius: 6px;
        text-align: center;
        padding: 16px 0;
      }
    }
  }
}
