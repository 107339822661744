@import '../../../variables';

.sidebar-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  width: 224px; // if adjusted must also adjust content margin in App.scss
  height: 100vh;
  position: fixed;
  background-color: $background-dark-blue;
  z-index: 3;
  padding-top: 24px;

  .main-button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }

  .footer-button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    margin-bottom: 16px;
  }
}
