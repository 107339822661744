@import '../../../../variables';

.auth-link-table {
  width: 100%;
  table-layout: auto;

  tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    gap: 6px;
  }

  thead {
    display: flex;
    background: linear-gradient(white, $xpektor-light-blue);
    border: 1px solid $xpektor-light-blue;
    border-radius: 6px;
    margin-bottom: 6px;
    padding-right: 12px;

    th {
      font-family: $font-family-normal;
      color: $system-dark;
      font-size: 12px;
      font-weight: 600;
      width: 14%;
      white-space: nowrap;

      .sortable {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 0;
        background: none;
        font-weight: inherit;
        font-family: inherit;
        color: inherit;
        padding: 0;
        gap: 6px;
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    height: 556px;
    overflow-y: scroll;
    gap: 6px;

    tr {
      border: 1px solid $xpektor-light-gray;
      border-radius: 6px;
      height: 72px;
      min-height: 72px;
      align-items: center;
      transition: 0.125s;

      &:hover {
        box-shadow: 2px 2px 8px $xpektor-mid-blue;
        transform: scale(1.0075);
        cursor: pointer;
      }

      td {
        overflow-x: hidden;
        overflow-y: hidden;
        font-family: $font-family-normal;
        font-size: 12px;
        width: 14%;
      }

      .icon-column {
        font-size: 14px;
      }

      .green-icon {
        color: green;
      }

      .red-icon {
        color: red;
      }
    }
  }

  .name-column {
    width: 16%;
  }

  .icon-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    width: max(60px, 10%);
  }

  .first-icon-column {
    margin-left: auto;
  }
}
