@import '../../../variables';

.faq-question-wrapper {
  display: flex;
  flex-direction: column;
  p {
    color: black;
    font-family: $font-family-normal;
    font-size: 14px;
    margin: 0;
    padding: 12px;
  }
  .faq-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $xpektor-light-gray;
    border-radius: 12px;
    background-color: white;
    align-items: center;
    z-index: 1;
    padding: 0 24px 0 12px;
    background-image: linear-gradient(transparent, $xpektor-light-blue);

    &:hover {
      background-image: linear-gradient(transparent, $xpektor-mid-blue);
    }
    p {
      font-weight: 600;
    }
  }

  .faq-answer-container {
    display: flex;
    opacity: 1;
    border-right: 1px solid $xpektor-light-gray;
    border-left: 1px solid $xpektor-light-gray;
    border-bottom: 1px solid $xpektor-light-gray;
    border-top: none;
    border-radius: 12px;
    white-space: pre-wrap;

    p {
      padding: 12px 24px;
    }
  }
  .answer-hidden {
    opacity: 0;
    height: 0;
  }
}
