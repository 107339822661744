@import '../../../variables';

.create-auth-link-modal {
  p {
    color: black;
    font-family: $font-family-normal;
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .modal-header {
    font-family: $font-family-head;
    margin: 0 12px;
  }

  .auth-link-modal-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    div {
      display: flex;
    }
    padding: 12px;

    .result-display {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid $xpektor-light-gray;
      border-radius: 6px;
      padding: 12px;
      margin-top: 12px;
      gap: 12px;
      text-align: center;

      p {
        font-family: $font-family-normal;
        padding: 0;
        margin: 0;
        font-size: 14px;
      }

      .result-title {
        font-size: 12px;
      }
    }

    .good-result {
      background-image: linear-gradient(transparent, $xpektor-light-blue);
    }

    .error-result {
      background-color: $xpektor-light-red;
      p {
        color: $xpektor-red;
      }
    }

    .xpektor-form {
      .xpektor-form-submit-button {
        width: 100%;
      }
    }

    .action-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;

      .xpektor-button {
        z-index: 1;
        width: 100%;
      }
    }
  }
}
