@import '../../../variables';

.tutorials-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .tutorial-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .tutorial-title {
      font-family: $font-family-head;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: black;
      margin: 24px 0;
    }

    .tutorial-accounting-nav {
      display: flex;
      flex-direction: row;
      background-image: linear-gradient(white, $xpektor-light-blue);
      width: 100%;
      justify-content: center;
      border-bottom: 1px solid $xpektor-light-gray;

      .tutorial-nav-button {
        width: 200px;
        height: 60px;
        border: none;
        background: none;

        .partner-logo {
          width: 110px;
          min-width: 110px;
          height: 32px;
          object-fit: contain;
        }

        &:hover {
          background-image: linear-gradient(white, rgb(209, 233, 255));
        }
      }
      .selected-nav-button {
        border-bottom: 3px solid $xpektor-blue;
      }
    }

    .tutorial-selection-nav {
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      .tutorial-button {
        width: 200px;
        height: 60px;
        border: none;
        border-bottom: 2px solid rgba(132, 146, 166, 0.3);
        background: none;

        &:hover {
          background-image: linear-gradient(white, $xpektor-light-blue);
        }
      }
      .selected-tutorial-button {
        border-color: $xpektor-blue;
      }
    }
  }
  .tutorial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin-top: 48px;
    border-left: 1px solid $xpektor-light-gray;
    border-right: 1px solid $xpektor-light-gray;
    img {
      max-width: 100%;
    }
  }
}
