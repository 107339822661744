@import '../../../variables';

.xpektor-bootstrap-modal {
  p {
    font-family: $font-family-normal;
    font-size: 14px;
    color: black;
    margin: 0;
  }
  .modal-dialog {
    .modal-header {
      padding-left: 24px;
      padding-right: 24px;
      .modal-title {
        font-size: 18px;
        font-weight: 600;
        font-family: $font-family-normal;
      }
      p {
        font-size: 18px;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 12px;

      .spaced-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .image-window {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $xpektor-light-gray;
        border-radius: 6px;
        max-width: 600px;
        aspect-ratio: 2/1;
        padding: 24px;
        margin-bottom: 12px;

        img {
          max-width: 100%;
          max-height: 100px;
          margin: auto 0;
        }

        .missing-text {
          font-size: 18px;
          font-weight: 400;
          margin: auto;
        }
      }
    }
  }
}
