@import '../../../variables';

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1;
  width: 100%;
  max-width: 1600px;
  margin-top: 36px;
  margin-bottom: 24px;

  .dashboard-nav-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;

    .dash-add-client {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      gap: 10px;

      .xpektor-button {
        width: 100%;
        width: 200px;
      }
    }
  }
}
