@import '../../../../variables';

.sysadmin-table {
  div {
    display: flex;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;

  .sysadmin-table-head {
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    background-image: linear-gradient(transparent, $xpektor-light-blue);
    gap: 6px;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      background: none;
      padding: 0;
      height: 48px;
      font-weight: 500;
      gap: 3px;

      &:enabled:hover {
        p,
        .sort-icon {
          color: $xpektor-blue;
        }
      }

      p {
        font-family: $font-family-normal;
        font-size: 12px;
        text-align: left;
      }

      .sort-icon {
        font-size: 10px;
      }

      &:disabled {
        color: black;
      }
    }
  }

  .table-content {
    flex-direction: column;
    width: 100%;
    gap: 6px;

    .table-item-row {
      border: 1px solid $xpektor-light-gray;
      align-items: center;
      border-radius: 6px;
      height: 48px;
      gap: 6px;

      p {
        font-size: 12px;
        word-wrap: break-word;
        line-height: 1.2;
      }

      .action-icon-button {
        border: 1px solid transparent;
        border-radius: 3px;
        padding: 6px;
        &:hover {
          border-color: $xpektor-blue;
          cursor: pointer;
          color: $xpektor-blue;
        }
      }

      .confirm-action-content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        width: 100%;
        border: 1px solid $xpektor-red;
        border-radius: 6px;
        background-image: linear-gradient(white, $xpektor-light-red);
        padding: 6px;

        button {
          border: 1px solid $xpektor-light-gray;
          border-radius: 3px;
          padding: 6px;
          width: 60px;
          background-color: white;

          &:hover {
            p {
              color: $xpektor-blue;
            }
            border-color: $xpektor-blue;
            background-image: linear-gradient(white, $xpektor-light-blue);
          }
        }

        .loading-icon {
          font-size: 16px;
          animation: rotation 0.75s infinite linear;
          color: $xpektor-red;
        }
      }

      .centered-content {
        justify-self: center;
      }

      .end-content {
        justify-self: flex-end;
      }
    }

    .no-items-item {
      font-size: 12px;
      font-family: $font-family-normal;
      border: 1px solid $xpektor-light-gray;
      border-radius: 6px;
      text-align: center;
      padding: 14px 0;
    }
  }
}
