.App {
  background-color: white;
  width: 100%;
  height: 100%;
  margin: 0px;

  .vertical-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 224px; // width of sidebar
    min-height: 100vh;
    min-width: 300px;

    .app-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;

      .ok {
        color: green !important;
      }

      .warning {
        color: #e5904a !important;
      }

      .error {
        color: #e14844 !important;
      }
    }
  }
}
