@import '../../../../variables';

.customer-history-modal {
  .modal-dialog {
    width: 100%;
    max-width: 1000px;
  }

  .modal-content {
    min-height: 90vh;

    .modal-body {
      .sysadmin-history-table {
        .month-actions {
          display: flex;
          flex-direction: column;
          gap: 6px;
          .month-title {
            padding: 6px 12px;
            font-weight: 600;
            font-size: 12px;
            border-radius: 6px;
            background-image: linear-gradient(
              to right,
              $xpektor-light-blue,
              white
            );
          }
        }

        .history-table-row {
          padding-left: 12px;
          padding-right: 12px;
          display: grid;
          grid-template-columns: repeat(5, minmax(0, 1fr));
          gap: 12px;
          box-shadow: 1px 2px 2px $xpektor-light-blue;
        }
      }
    }
  }
}
