@import '../../variables';

.minimal-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  .form-input-container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 92px;
    margin-bottom: 6px;

    label {
      position: absolute;
      color: white;
      font-family: $font-family-normal;
      font-size: 14px;
      text-align: left;
      top: 14px;
      left: 6px;
      transition: 0.125s;
    }

    .form-input {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      height: 50%;
      width: 100%;

      input {
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        background: none;
        height: 100%;
        width: 100%;
        color: white;
        font-size: 14px;
        font-family: $font-family-normal;
        padding-right: 40px;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }

      input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .form-input-icon {
        padding-right: 12px;
        position: absolute;
        right: 0;
        color: rgba(255, 255, 255, 0.5);
      }
      .clickable-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .input-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $xpektor-red;
      font-size: 11px;
      height: 22px;

      .input-info-icon {
        padding-right: 6px;
      }

      p {
        margin: 0;
        padding: 0;
        font-family: $font-family-normal;
        color: $xpektor-red;
        font-size: 11px;
      }
    }

    .input-info-ok {
      color: green;
      p {
        color: green;
      }
    }
  }

  .input-focus {
    label {
      top: -14px;
      left: 2px;
      font-size: 12px;
    }
    .form-input {
      input {
        border-color: white;
      }
      .form-input-icon {
        color: white;
      }
    }
  }

  .xpektor-form-submit-button {
    display: flex;
    width: 100%;
    padding: 12px 0;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 26px;
    background-color: white;
    margin-top: auto;
    color: black;
    font-family: $font-family-normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 24px */
    letter-spacing: 0.3px;
    transition: 0.125s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
