@import '../../../../../variables';

.user-table {
  width: 100%;
  table-layout: auto;

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    box-shadow: none;

    th {
      flex: 1;
      justify-content: flex-start;
      width: 25%;
      padding: 0 6px;
      gap: 6px;
      &:last-child {
        width: 20%;
        flex: none;
        justify-content: center;
      }
      &:first-child {
        flex: none;
      }
    }
    td {
      flex: 1;
      text-align: left;
      word-wrap: break-word;
      width: 25%;
      padding: 0 6px;

      &:last-child {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 20%;
        flex: none;
      }
      &:first-child {
        width: 25%;
        flex: none;
      }
    }
  }

  thead {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    box-shadow: none;
    background: linear-gradient(white, $xpektor-light-blue);
    border: 1px solid $xpektor-light-blue;
    border-radius: 6px;
    margin-bottom: 6px;
    z-index: 1;

    // sticky stuff
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 80px;

    th {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
      color: $system-dark;
      font-family: $font-family-normal;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      background: none;
      padding: 0;

      .sortable {
        color: inherit;
        font-weight: inherit;
        font-family: inherit;
        border: 0;
        background: none;
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .user-item-wrapper {
      align-items: center;
      height: 72px;
      border-radius: 8px;
      border: 1px solid rgba(132, 146, 166, 0.3);
      margin-bottom: 8px;

      td {
        color: var(--Black, #16192c);
        font-family: $font-family-normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }

    .user-table-item-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 16px;
      color: #425466;
      background: 0;
      border: 1px solid rgba(1, 1, 1, 0);
      border-radius: 4px;
      padding: 10px;
      transition: color 0.125s, border 0.125s;

      &:hover {
        border: 1px solid $xpektor-blue;
        color: $xpektor-blue;
      }
    }
  }
}
