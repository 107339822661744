@import '../../../variables';

.company-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 24px;
  gap: 12px;

  .company-info-head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 76px;
    width: 100%;

    .company-info-details {
      display: flex;
      flex-direction: column;
      width: 100%;

      .company-info-title-row {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .company-title {
          color: var(--Black, #16192c);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $font-family-normal;
          font-size: 42px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 0;
          margin: 0 0 auto 0;
        }
      }

      .company-info-sub-title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .action-button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;
      margin-left: auto;

      .action-button-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;

        .xpektor-button {
          width: 128px;
          height: 28px;

          button {
            background-color: rgb(255, 242, 242);
            color: $xpektor-blue;
            border: 1px solid #e14844;
            color: #e14844;
            font-family: $font-family-head;
            border-radius: 16px;

            &:hover:enabled {
              filter: brightness(95%);
            }

            .icon {
              color: #e14844;
              font-size: 12px;
            }
          }

          .connected {
            background-color: rgb(237, 255, 237);
            border-color: green;
            color: green;
            .icon {
              color: green;
            }
          }
          .expired {
            background-color: #fff9f3;
            border-color: #e5904a;
            color: #e5904a;
            .icon {
              color: #e5904a;
            }
          }
        }

        .link-broken {
          font-family: $font-family-normal;
          font-size: 14px;
          text-decoration: none;
          width: 142px;
          height: 90%;
        }

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
      }
      .sub-action-row {
        .xpektor-button {
          height: 36px;
          width: 200px;

          button {
            background-image: linear-gradient(white, $xpektor-light-blue);
            color: $xpektor-blue;
            border: 1px solid $xpektor-blue;
            border-radius: 6px;

            .icon {
              color: $xpektor-blue;
              font-size: 14px;
            }

            &:hover {
              background-image: linear-gradient(white, $xpektor-mid-blue);
            }
          }
        }
      }
    }
  }

  .company-info-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .sub-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 21px;

    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 50%;
      mask-image: linear-gradient(black, black);

      .status-icon {
        font-size: 24px;
        margin: 0 10px;
        transition: color 0.125s;
      }

      .status-icon-broken {
        font-size: 16px;
        margin: 0 10px;
      }

      .status-text {
        color: black;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $font-family-normal;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }

      .ok {
        color: green;
      }

      .warning {
        color: #e5904a;
      }

      .error {
        color: red;
      }
    }
  }
}
