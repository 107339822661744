@import '../../../../variables';

.create-customer-modal {
  div {
    display: flex;
  }

  .classic-form {
    gap: 64px;
    .form-row {
      flex-direction: row;
      gap: 24px;
      flex: 1;
      flex-wrap: wrap;
    }

    .form-column {
      flex-direction: column;
      flex: 1;
    }

    .form-input-container {
      min-width: 200px;
    }

    .advice {
      select {
        background-color: pink;
      }
    }

    .finance {
      select {
        background-color: rgb(224, 242, 255);
      }
    }

    .subsegment {
      select {
        background-color: rgb(236, 236, 236);
      }
    }

    select {
      border-radius: 16px;
      option {
        background-color: $xpektor-light-blue;
      }
    }
  }
}
