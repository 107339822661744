@import '../../variables';

.datapanel-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 32px;
  border-radius: 6px;
  border: 1px solid $xpektor-light-gray;
  margin: 0;
  scroll-margin-top: 100px;
  transition: box-shadow 0.1s;
  box-shadow: 1px 1px 2px $xpektor-mid-blue;

  p {
    font-family: $font-family-normal;
    font-style: normal;
    padding: 0;
    margin: 0;
  }

  .datapanel-title-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $xpektor-light-gray;
    background-image: linear-gradient(white, $xpektor-light-blue);

    p {
      color: black;
    }

    .datapanel-title-icon {
      margin-left: auto;
      margin-right: 20px;
    }

    .datapanel-title-icon-background {
      display: flex;
      padding: 4px 16px;
      margin-left: auto;
      margin-right: 18px;
      border-radius: 6px;
      border: 1px solid $xpektor-light-gray;

      p {
        margin: 0;
        padding: 0;
      }
    }

    .grade-A {
      background-image: linear-gradient(rgba(255, 255, 255, 0), #c3e9c4);
      p {
        color: #28572a;
      }
    }

    .grade-B {
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgb(225, 255, 191)
      );
      p {
        color: #479346;
      }
    }

    .grade-C {
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgb(255, 253, 231)
      );
      p {
        color: #f4d644;
      }
    }

    .grade-D {
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgb(255, 234, 195)
      );
      p {
        color: #e5904a;
      }
    }

    .grade-E {
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgb(255, 208, 208)
      );
      p {
        color: #e14844;
      }
    }

    .grade-- {
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgb(233, 233, 233)
      );
      p {
        color: rgb(109, 109, 109);
      }
    }
  }

  .large-title-container {
    p {
      font-size: 16px;
      font-weight: 700;
      margin: 16px;
    }
  }

  .medium-title-container {
    p {
      font-size: 14px;
      font-weight: 600;
      margin: 14px;
    }
  }

  .small-title-container {
    p {
      font-size: 12px;
      font-weight: 600;
      margin: 12px;
    }
  }

  .datapanel-content-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .datapanel-centered-content {
    justify-content: center;
  }

  .datapanel-line-chart {
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    height: 180px;

    svg {
      overflow: visible;
    }
  }

  .datapanel-bar-entry {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $xpektor-light-gray;
    height: 100%;
    min-height: 32px;

    &:last-child {
      border: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .bar-fill {
      height: 100%;
      background-image: linear-gradient(to right, $xpektor-cyan, $xpektor-blue);
      transition: width ease-out 1s;
    }

    p {
      position: absolute;
      top: calc(50% - 6px);
      left: 12px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .scrollable-panel-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sub-title {
    color: var(--747-a-80, #747a80);
    font-size: 14px;
    font-weight: 400;
    margin: 16px auto 0 16px;
    padding: 0;
  }

  .datapanel-entry-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0 16px;
    border-bottom: 1px solid $xpektor-light-gray;

    p {
      font-size: 14px;
    }

    .entry-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 16px 0;
      border: none;
      background: none;

      &:hover {
        cursor: default;
      }

      .entry-icon {
        font-size: 14px;
        margin-right: 10px;
        color: black;
      }

      .info-icon {
        font-size: 14px;
        margin-left: 6px;
        color: black;
        transition: 0.05s;

        &:hover {
          color: $xpektor-blue;
          transform: scale(1.2);
          cursor: default;
        }
      }

      .trigger-entry-end-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100px;
        margin-left: auto;
      }

      .datapanel-entry-tag {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        align-items: center;
        gap: 3px;
        border-radius: 12px;
        background-color: $xpektor-light-gray;
        padding: 3px 6px;
        transition: 0.05s;

        &:hover {
          cursor: default;
          color: $xpektor-blue;
          p {
            color: $xpektor-blue;
          }
        }

        p {
          color: black;
          font-size: 10px;
          font-weight: 600;
        }
      }
      .entry-expand-icon {
        &:last-child {
          margin-left: auto;
        }
      }
    }

    .small-title-entry {
      padding: 6px 0;
      .entry-text,
      .entry-value,
      .value,
      .value-sign {
        font-size: 12px;
      }
    }

    .entry-expandable {
      &:hover {
        cursor: pointer;
        box-shadow: -16px 0px $xpektor-light-blue;
        background-image: linear-gradient(
          to right,
          $xpektor-light-blue,
          rgba(1, 1, 1, 0)
        );
      }
    }

    .entry-expanded-content {
      display: flex;
      width: 100%;
      margin-top: 12px;
      padding: 0px 16px 16px 16px;

      p {
        font-size: 12px;
        color: black;
      }

      ol {
        margin-top: 1rem;
      }
      li {
        font-size: 12px;
      }

      .trigger-tips-container {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid $xpektor-light-gray;
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border: 0;
        }
      }
    }

    &:last-child {
      border: 0;
    }

    .entry-text {
      color: black;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .entry-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      padding-left: 6px;
      gap: 4px;

      .value {
        color: var(--000000-primary, #000);
      }

      .value-sign {
        color: var(--Grey-light, #8492a6);
      }
    }
  }

  .datapanel-normal-button {
    display: flex;
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    background: none;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 48px;
    margin: 12px auto;

    &:enabled:hover {
      background-color: $xpektor-light-blue;
    }

    p {
      color: black;
      padding: 0;
      margin: 0;
      font-size: 14px;
    }
  }

  .disabled-button {
    p {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .datapanel-expand-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    background: none;
    border: none;
    margin: 0 12px;
    padding: 6px;

    p {
      font-family: $font-family-normal;
      color: black;
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    .expand-icon {
      font-size: 12px;
    }

    .expand-loading-icon {
      width: 32px;
      height: 32px;
      background-image: url('../../Images/LoadingWindow/loading_circle_b.png');
      background-size: contain;
      animation: rotation 0.75s infinite linear;
    }
  }

  .double-value-entry-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
    margin: 0 16px;
    border-bottom: 1px solid $xpektor-light-gray;

    p {
      color: black;
      font-size: 14px;
      font-family: $font-family-normal;
      margin: 0;
      padding: 0;
    }

    .datapanel-gray-text {
      color: var(--747-a-80, #747a80);
      font-size: 12px;
    }

    .double-value-entry-title-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .double-value-entry-column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      .double-value-entry-row {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
      }
    }

    &:last-child {
      border: 0;
    }
  }

  .datapanel-green {
    color: green !important;
  }

  .datapanel-red {
    color: red !important;
  }
}
