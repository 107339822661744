@import '../../variables';

.external-auth-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // max-height: 96vh;
  background-color: $xpektor-light-blue;

  .auth-complete {
    color: $xpektor-blue;
  }
  .auth-incomplete {
    color: darkred;
  }

  .auth-header {
    display: flex;
    flex-direction: column;
    width: 180px;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
    p {
      margin: 0;
      margin-right: auto;
      padding: 0;
      font-family: $font-family-head;
      font-size: 14px;
      font-weight: 600;
      color: black;
    }

    .logo {
      width: 160px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .auth-footer {
    display: flex;
    flex-direction: column;
    // height: 50px;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 20px;

    .why-link {
      font-size: 14px;
      color: rgba(134, 146, 166, 1);
      margin-bottom: 24px;
    }

    .powered-by {
      display: flex;
      flex-direction: column;
      width: 180px;
      p {
        margin: 0;
        margin-right: auto;
        padding: 0;
        font-family: $font-family-head;
        font-size: 14px;
        font-weight: 600;
        color: black;
      }

      .logo {
        display: flex;
        align-self: flex-end;
        width: 160px;
        height: auto;
        filter: invert(1);
      }
    }
  }
  .no-accounting {
    margin-top: 20px;
  }
  .xpektor-modal {
    background-color: rgba(0, 0, 0, 0.05);
    justify-content: center;

    .modal-content-window {
      .xpektor-modal-header {
        border: none;
      }
      .xpektor-modal-body {
        flex-direction: column;
        align-items: center;

        .loading-wrapper {
          top: 45%;
        }
        .auth-step {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .integration-options-column {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            gap: 12px;
            overflow-y: hidden;

            .integration-partner-button-disabled {
              cursor: default;
            }
          }

          .auth-step-completed-content {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 80px 0;
            gap: 12px;
            font-size: 24px;
            color: green;

            .auth-complete-icon {
              color: $xpektor-blue;
              font-size: 32px;
            }

            .auth-complete-header {
              font-size: 16px;
              font-family: $font-family-normal;
              font-weight: 600;
              margin-top: 24px;
            }

            .auth-complete-text {
              font-size: 14px;
              font-family: $font-family-normal;
              max-width: 600px;
              text-align: center;
            }
          }

          .auth-step-header {
            margin-top: 24px;
            font-family: $font-family-normal;
            font-size: 16px;
            font-weight: 600;
          }
          .auth-step-description {
            font-family: $font-family-normal;
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px;
            text-align: justify;
          }
          .auth-step-help-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            margin-top: 6px;
            border: none;
            background: none;

            &:hover {
              p {
                color: rgb(142, 174, 243);
              }
              .auth-step-help-link-icon {
                color: rgb(142, 174, 243);
              }
            }
            p {
              font-size: 14px;
              color: $xpektor-blue;
              text-decoration: underline;
            }

            .auth-step-help-link-icon {
              color: $xpektor-blue;
            }
          }
          .auth-step-error {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            border: 1px solid darkred;
            border-radius: 6px;
            padding: 12px 24px;
            margin-top: 12px;
            background-color: rgb(255, 242, 242);
            max-width: 800px;
            p {
              color: darkred;
              font-size: 14px;
              gap: 6px;
            }
          }

          .auth-default-error {
            display: flex;
            justify-content: center;
            width: 600px;
            margin-top: 100px;
            border: 1px solid darkred;
            border-radius: 6px;
            padding: 12px 24px;
            background-color: rgb(255, 242, 242);
            p {
              font-family: $font-family-normal;
              font-size: 16px;
              color: darkred;
            }
          }

          .integration-options {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 20px;
            width: 100%;

            .integration-partner {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 60px 0;
              .partner-logo {
                width: 150px;
                height: 34px;
                margin-bottom: 30px;
              }

              .info-bubble {
                display: flex;
                align-items: center;
                text-align: center;
                margin-top: 12px;
                padding: 6px;
                min-height: 60px;
                width: 150px;
                border: 1px solid
                  var(--cfdbd-5-outline-on-light, rgba(207, 219, 213, 0.7));
                border-radius: 6px;
                background-color: $xpektor-light-blue;
                color: black;
                font-family: $font-family-normal;
                font-size: 12px;
              }
            }
          }

          .bank-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: flex-end;
            margin-bottom: 10px;
            margin-top: 24px;

            .country-item {
              display: flex;
              flex-direction: row;
              gap: 6px;
              align-items: center;

              p {
                font-size: 15px;
              }
            }
          }

          .bank-grid {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;
            overflow-y: scroll;
            height: 440px;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            margin-bottom: 12px;

            .bank-button {
              border-radius: 6px;
              border: 1px solid
                var(--cfdbd-5-outline-on-light, rgba(207, 219, 213, 0.7));
              padding: 15px;
              height: 100px;

              &:hover {
                border-color: $xpektor-blue;
                background-color: $xpektor-light-blue;
              }

              p {
                font-size: 13px;
                margin-bottom: 10px;
              }

              .button-content-container {
                height: 100%;
                width: 100%;

                .bank-image {
                  width: 100%;
                  height: 30px;
                  object-fit: contain;
                }
              }
            }
          }
        }

        .external-auth-wrapper
          .xpektor-modal
          .modal-content-window
          .xpektor-modal-body
          .auth-step
          .auth-step-completed-content {
          width: 100%;
        }

        .auth-step-nav {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          margin-top: 12px;
          padding-top: 12px;
          margin-bottom: 6px;
        }
        .auth-step-nav-info {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
          p {
            font-size: 14px;
            font-family: $font-family-head;
            padding-right: 6px;
            margin: 0;
          }
        }
        .xpektor-button {
          width: 120px;
          button {
            &:disabled {
              background-color: rgb(131, 168, 255);
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .auth-step {
    overflow: auto;
  }
  .external-auth-wrapper
    .xpektor-modal
    .modal-content-window
    .xpektor-modal-body
    .auth-step
    .bank-nav
    .xpektor-searchbar {
    min-width: 200px;
    margin-left: 0;
  }
  .external-auth-wrapper
    .xpektor-modal
    .modal-content-window
    .xpektor-modal-body
    .auth-step
    .bank-grid {
    grid-template-columns: 1fr 1fr;
    padding-right: 10px;
    padding-left: 0;
    grid-gap: 8px;
    height: 200px;
  }
  .external-auth-wrapper
    .xpektor-modal
    .modal-content-window
    .xpektor-modal-body
    .auth-step
    .bank-grid
    .bank-button
    .button-content-container
    .bank-image {
    width: 75%;
    height: auto;
  }
  .external-auth-wrapper
    .xpektor-modal
    .modal-content-window
    .xpektor-modal-body
    .auth-step
    .bank-grid
    .bank-button {
    padding: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .auth-step {
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) {
  .auth-step {
    overflow: auto;
  }
}

@media only screen and (min-width: 992px) {
  .auth-step {
    overflow: auto;
  }
}

@media only screen and (min-width: 1200px) {
}
