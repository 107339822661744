.background-section {
  position: relative;
  flex-direction: column;
  width: 100%;
}

.background-end {
  background-image: url('../../../Images/Home/home_footer_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
}

.background-element {
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: 1;

  @media screen and (max-width: 450px) {
    display: none;
  }
}
