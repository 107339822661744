@import '../../variables';

.editable-panel-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 110px;
  border-radius: 12px;
  border: 1px solid $xpektor-light-gray;
  box-shadow: 1px 1px 2px $xpektor-mid-blue;

  p {
    font-family: $font-family-normal;
    margin: 0;
    padding: 0;
  }

  .editable-panel-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $xpektor-light-gray;
    background-image: linear-gradient(white, $xpektor-light-blue);

    p {
      color: black;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      margin: 16px 24px;
      padding: 0;
    }

    .edit-icon {
      font-size: 20px;
      margin-right: 20px;
      margin-left: auto;
    }

    .edit-hover {
      cursor: pointer;
      color: $xpektor-blue;
    }

    .confirm-icon {
      font-size: 20px;
      margin-right: 10px;
      margin-left: auto;
      padding: 5px;
      color: green;
    }

    .cancel-icon {
      font-size: 20px;
      margin-right: 20px;
      margin-left: 0px;
      padding: 5px;
      color: red;
    }

    .confirm-cancel-hover {
      cursor: pointer;
      background-color: $xpektor-light-blue;
    }
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    margin: 24px;
  }

  .editable-entry-container {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }

    .value {
      color: black;
      font-size: 14px;
      pointer-events: none;
      border: 1px solid rgba(255, 255, 255, 0);
      background: none;
      padding: 6px;
      outline: none;
    }

    .editable-value {
      color: black;
      font-size: 14px;
      border-radius: 6px;
      border: 1px solid $xpektor-light-gray;
      background-color: $xpektor-light-blue;
      padding: 6px;
      outline: none;

      &:focus {
        border-color: $xpektor-blue;
      }
    }

    .input-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: red;
      font-size: 11px;
      margin-bottom: 2px;
      height: 18px;

      .input-info-icon {
        padding-right: 6px;
      }

      p {
        margin: 0;
        padding: 0;
        font-family: $font-family-normal;
        color: #e14844;
        font-size: 11px;
      }
    }
  }
}
