@import '../../../../variables';

.company-table {
  width: 100%;
  table-layout: auto;

  tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    box-shadow: none;
    gap: 6px;

    .name-column {
      width: 25%;
    }

    .grade-column {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 8%;
      margin-right: auto;
    }

    .kyc-column {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80px;
      margin-right: auto;
      .kyc-display {
        padding: 6px 0;
        width: 120px;
        border: 1px solid $xpektor-light-gray;
        border-radius: 6px;
        margin: 0;
        font-size: 12px;
      }
    }

    .connection-column {
      display: flex;
      width: max(60px, 10%);
      justify-content: center;
      text-align: center;

      .connection-icon {
        font-size: 16px;
      }
    }
  }

  thead {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    box-shadow: none;
    background: linear-gradient(white, $xpektor-light-blue);
    border: 1px solid $xpektor-light-blue;
    border-radius: 6px;
    margin-bottom: 6px;

    // sticky stuff
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 80px;

    th {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $system-dark;
      font-family: $font-family-normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      background: none;

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        gap: 6px;
        padding: 0;
      }

      .sortable {
        border: 0;
        background: none;
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .company-item-wrapper {
      align-items: center;
      min-height: 72px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border: 1px solid $xpektor-light-gray;
      border-left: 4px solid $xpektor-light-gray;
      margin-bottom: 8px;
      box-shadow: 1px 1px 2px $xpektor-mid-blue;
      transition: 0.125s;

      &:hover {
        box-shadow: 2px 2px 4px $xpektor-mid-blue;
        border-left-color: $xpektor-blue;
        transform: scale(1.0075);
        cursor: pointer;
      }

      td {
        padding: 0;
        font-family: $font-family-normal;
        font-size: 14px;
        font-style: normal;
        line-height: 21px;
      }

      .grade-icon {
        padding: 3px 16px;
        margin: 0;
        border-radius: 6px;
        border: 1px solid $xpektor-light-gray;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }

      .grade-icon-disconnected {
        border-width: 2px;
        border-style: dashed;
      }

      .grade-A {
        background: linear-gradient(rgba(255, 255, 255, 0), #c3e9c4);
        color: #28572a;
        border-color: #28572a;
      }

      .grade-B {
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(225, 255, 191));
        color: #479346;
        border-color: #479346;
      }

      .grade-C {
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 253, 231));
        color: #f4d644;
        border-color: #f4d644;
      }

      .grade-D {
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 234, 195));
        color: #e5904a;
        border-color: #e5904a;
      }

      .grade-E {
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 208, 208));
        color: #e14844;
        border-color: #e14844;
      }

      .grade-- {
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(233, 233, 233));
        color: rgb(109, 109, 109);
        border-color: rgb(109, 109, 109);
      }

      .kyc-open {
        color: $xpektor-blue;
        background: linear-gradient(rgba(255, 255, 255, 0), #3e66fb30);
      }

      .kyc-sent {
        color: #eca60f;
        background: linear-gradient(rgba(255, 255, 255, 0), #eca60f30);
      }

      .kyc-signed {
        color: #006400;
        background: linear-gradient(rgba(255, 255, 255, 0), #00640030);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    thead {
      th {
        font-size: 12px;
      }
    }

    tr {
      .connection-column {
        .connection-icon {
          font-size: 14px;
        }
      }
    }
  }
}
